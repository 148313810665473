'use client';

import { Media } from '@components/media/media.module';
import type { MediaProps } from '@components/media/media.types';
import type ImageProps from '@contentstack/content-types/image/image.props';
import { Dialog, DialogMain } from '@vcc-package/overlays';
import { useTracker } from '@volvo-cars/tracking';
import { useState } from 'react';
import type { MediaCtUiResponsiveAspectRatio } from './media.types';

type ImageMediaProps = {
  imageAsset: ImageProps;
  responsiveAspectRatio: MediaCtUiResponsiveAspectRatio;
};

export function Image({ imageAsset, responsiveAspectRatio }: ImageMediaProps) {
  const image = imageAsset.imageAsset;

  const [isOpen, setIsOpen] = useState(false);
  const tracker = useTracker();

  if (!imageAsset.imageAsset) {
    return null;
  }

  const mediaItem: MediaProps['items'][number] = {
    imageSources: {
      smAspectRatio: responsiveAspectRatio,
      sm: {
        src: image.src,
        alt: image.alt,
      },
    },
  };

  function handleClick() {
    setIsOpen(true);

    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: `Image Media Alt: ${image.alt}`,
      eventCategory: 'sfImage',
    });
  }

  return (
    <>
      <button type="button" className="w-full m-8" onClick={handleClick}>
        <Media items={[mediaItem]} />
      </button>
      <ImageModal
        items={[mediaItem]}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

type ImageModalProps = {
  open: boolean;
  onClose: () => void;
} & MediaProps;

function ImageModal({ open, onClose, ...mediaProps }: ImageModalProps) {
  return (
    <Dialog open={open} onClose={onClose} size="full">
      <DialogMain>
        <Media {...mediaProps} />
      </DialogMain>
    </Dialog>
  );
}
