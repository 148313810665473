'use client';

import {
  type AccessoryRequestFormRolloutSetting,
  RetailerCta,
} from '@components/cta/product-details-cta/retailer-cta/retailer-cta.module';
import { ImageWithFallback } from '@components/image-with-fallback/image-with-fallback.module';
import { Media } from '@components/media/media.module';
import { PAGE_NAMING } from '@config';
import type ProductProps from '@contentstack/content-types/product/product.props';
import { useDictionaries } from '@contexts/dictionaries/dictionaries.provider';
import { validSiteSlug } from '@utils/helpers/helpers';
import { cssMerge } from '@volvo-cars/css/utils';
import { IconButton } from '@volvo-cars/react-icons';
import { useParams } from 'next/navigation';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  parseCompatibility,
  parseMediaList,
} from './product-card-wrapper.utils';

const CTA_CHARACTER_LIMIT = 25;

// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @volvo-cars/css/no-custom-class */
export function ProductCardModal({
  compatibilityList,
  description,
  mediaList,
  priceOrText,
  productName,
  productSlug,
  accessoryRequestFormRolloutSettings,
}: ProductProps & {
  accessoryRequestFormRolloutSettings?: AccessoryRequestFormRolloutSetting;
}) {
  const dialogContainer = useRef<HTMLDialogElement>(null);
  const [isOpen, setIsOpen] = useState(
    typeof window !== 'undefined' && window.location.hash === `#${productSlug}`,
  );

  function handleCloseModal() {
    if (window.location.hash === `#${productSlug}`) {
      window.location.hash = '_';
      setIsOpen(false);
    }
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') handleCloseModal();
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  function hashChangeHandler() {
    const currentHash = window.location.hash;
    setIsOpen(currentHash === `#${productSlug}`);
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler);
    return () => window.removeEventListener('hashchange', hashChangeHandler);
  }, []);

  useLayoutEffect(() => {
    if (isOpen) {
      dialogContainer.current?.showModal();
    } else {
      dialogContainer.current?.close();
    }
  }, [isOpen]);

  const { siteSlug } = useParams();

  const { getDictionaryItemByName } = useDictionaries();

  const retailerCtaTextContent = getDictionaryItemByName('App.CTA.retailer');
  const accessoryRequestFormCtaTextContent = getDictionaryItemByName(
    'App.CTA.requestContact',
  );

  const mediaItems = useMemo(() => parseMediaList(mediaList), [mediaList]);
  const compatibilityItems = useMemo(
    () =>
      parseCompatibility({
        list: compatibilityList,
        productSlug,
      }),
    [compatibilityList, productSlug],
  );
  const containerBaseStyles =
    'w-full button-group grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-16';

  const containerStyles = cssMerge(
    containerBaseStyles,
    `${
      retailerCtaTextContent.length > CTA_CHARACTER_LIMIT ||
      accessoryRequestFormCtaTextContent.length > CTA_CHARACTER_LIMIT
        ? 'md:grid-cols-1 justify-auto'
        : null
    }`,
  );

  return (
    <dialog className="dialog-large h-fit" ref={dialogContainer}>
      <header slot="header">
        <div slot="close">
          <IconButton
            icon="x"
            aria-label="Close"
            bleed
            onClick={handleCloseModal}
            variant="clear"
          />
        </div>
        <h2 className="font-medium">{productName}</h2>
      </header>
      <article slot="main">
        <div className="flex-col gap-32">
          <Media items={mediaItems} isModalOpen={isOpen} />
          <div className="stack-text">
            <h2 className="heading-2">{productName}</h2>
            <p className="lg:font-20 font-16">{priceOrText}</p>
            <p>{description}</p>
          </div>
          {compatibilityItems && (
            <div className="flex-col">
              {compatibilityItems.map((item) => (
                <div className="flex-row gap-16 items-center" key={item.text}>
                  <div className="bg-secondary w-48 h-48 p-8 rounded-sm">
                    <ImageWithFallback
                      className="w-full h-full aspect-1/1"
                      fallbackImage="iron-mark"
                      fallbackSize="S"
                      src={item.icon.src}
                      alt={item.icon.alt}
                      height={24}
                      width={24}
                      sourceComponent="CampaignProductModal"
                    />
                  </div>
                  <p className="font-16">{item.text}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </article>
      <div className="w-full flex-row justify-center" slot="footer">
        <RetailerCta
          retailerCtaTextContent={retailerCtaTextContent}
          siteSlug={validSiteSlug(`${siteSlug}`)}
          page={PAGE_NAMING.CampaignPage}
          accessoryRequestFormRolloutSettings={
            accessoryRequestFormRolloutSettings
          }
          accessoryRequestFormCtaTextContent={
            accessoryRequestFormCtaTextContent
          }
          overrideCtaStyles={{
            container: containerStyles,
            accessoryRequestCta: 'm-0 button-outlined w-full',
            retailerCta: 'button-filled w-full ',
          }}
          productHandle={productSlug}
        />
      </div>
    </dialog>
  );
}
