'use client';

import type { AccessoryRequestFormRolloutSetting } from '@components/cta/product-details-cta/retailer-cta/retailer-cta.module';
import { ProductCard } from '@components/product-card/product-card.module';
import type ProductProps from '@contentstack/content-types/product/product.props';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import { useTracker } from '@volvo-cars/tracking';
import { ProductCardModal } from './product-card-modal';
import { parseProduct } from './product-card-wrapper.utils';

type ProductCardWrapperProps = ProductProps & {
  isFromProductCarousel?: boolean;
  isFromProductGrid?: boolean;
  accessoryRequestFormSettings?: AccessoryRequestFormRolloutSetting;
};

export function ProductCardWrapper({
  compatibilityList,
  description,
  isFromProductCarousel = false,
  isFromProductGrid = false,
  productSlug,
  productName,
  priceOrText,
  mediaList,
  accessoryRequestFormSettings,
}: ProductCardWrapperProps) {
  const tracker = useTracker();

  const parsedMediaList = mediaList.filter(({ media }) => {
    if (!media) {
      reportDetailedErrorMessage({
        culprit: 'ContentStack > ProductCardWrapper UI Component > mediaList',
        errorCategory: errorCategories.CONTENT_DELIVERY,
        details: {
          missingParts: ['media'],
        },
      });

      return false;
    }
    return true;
  });

  const product = parseProduct({
    productSlug,
    productName,
    priceOrText,
    mediaList: parsedMediaList,
  });

  if (!product) return null;

  function handleOnClickAnalytics() {
    tracker.customEvent({
      eventAction: 'button|click',
      eventLabel: `Product Grid Card: ${productName}`,
      eventCategory: 'sfProductCard',
    });
  }

  return (
    <>
      <ProductCard
        smAlternateAspectRatio={isFromProductCarousel}
        handleOnClick={handleOnClickAnalytics}
        fullSizeImg={isFromProductGrid}
        textAlignStart
        {...product}
      />
      <ProductCardModal
        compatibilityList={compatibilityList}
        description={description}
        mediaList={parsedMediaList}
        priceOrText={priceOrText}
        productName={productName}
        productSlug={productSlug}
        accessoryRequestFormRolloutSettings={accessoryRequestFormSettings}
      />
    </>
  );
}
