import { useSharedContentTranslate } from '@vcc-www/shared-content/DictionariesProvider';
import React from 'react';
interface MessageProps {
  onClick?: (ev: React.MouseEvent) => void;
  children?: React.ReactNode;
}

const Message = ({ onClick, children }: MessageProps) => {
  const sharedContentTranslate = useSharedContentTranslate();

  const linkText = sharedContentTranslate('retailerPicker.infoLink');
  const infoText = sharedContentTranslate('retailerPicker.info', {
    infoLink: linkText,
  });
  const [textBefore = '', textAfter = ''] = infoText.split(linkText);

  return (
    <div className="border-l-2 p-16 border-accent-blue bg-secondary">
      {infoText && linkText && (
        <>
          {textBefore}
          <button type="button" className="link-inline" onClick={onClick}>
            {linkText}
          </button>
          {textAfter}
        </>
      )}

      {children}
    </div>
  );
};

export default Message;
