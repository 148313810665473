'use client';

import { Video_EXPERIMENTAL } from '@vcc-package/media/EXPERIMENTAL';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { cssJoin } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';
import { Track, type TrackProps } from '@volvo-cars/tracking';
import type React from 'react';
import type promotionContent from '../../content-management/content-types/PromotionContent.props';
import styles from './PromotionDisplay.module.css';

interface VideoProps {
  videos: promotionContent['responsiveVideos'];
  toggleButtonTrackingProps: TrackProps;
}

const Video: React.FC<VideoProps> = ({ videos, toggleButtonTrackingProps }) => {
  const sharedTranslate = useSharedComponentsTranslate();
  if (!videos) return null;

  const pauseAriaLabel = sharedTranslate('VideoPlayer.controls.pause');
  const playAriaLabel = sharedTranslate('VideoPlayer.controls.play');

  return (
    <Video_EXPERIMENTAL
      className="w-full aspect-4/5 md:aspect-16/9 lg:aspect-21/9"
      videos={videos}
      customControls={(togglePlaying, isPlaying) => {
        return (
          <Track {...toggleButtonTrackingProps}>
            <button
              className={cssJoin(
                'absolute end-24 z-navigation',
                styles['video-toggle-cta'],
              )}
              onClick={togglePlaying}
              type="button"
              aria-label={isPlaying ? pauseAriaLabel : playAriaLabel}
            >
              <Icon icon={isPlaying ? 'pause' : 'play'} size={24} />
            </button>
          </Track>
        );
      }}
    />
  );
};

export default Video;
