import { useSharedContentTranslate } from '@vcc-www/shared-content/DictionariesProvider';
import { getMarketSite } from '@volvo-cars/market-sites';
import { RoadLengthUnit } from '@volvo-cars/market-sites/src/units';
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable react/button-has-type */
import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
} from 'react';
import type { Distance, Retailer, RetailerDetails } from './types';

const formatDistance = (distance: Distance) => {
  const distanceUnits = {
    Kilometers: 'km',
    Miles: 'mi',
  };
  return `${distance.value && distance.value.toFixed(1)} ${
    distanceUnits[distance.unit as keyof typeof distanceUnits]
  }`;
};

const OptionalText = ({
  children,
  visible = true,
}: {
  children: ReactNode;
  visible?: boolean;
}) => {
  if (!children || !visible) {
    return null;
  }
  return <p className="text-secondary">{children}</p>;
};

type SelectedRetailerCardProps = {
  retailer: Retailer;
  isSelected: true;
  canChange: boolean;
  onClear: () => void;
  onSelectRetailer?: undefined;
  dataAutoId?: string;
  roadLengthUnit: RoadLengthUnit;
  volvoCountryCode?: string;
};

type RetailerCardProps = {
  retailer: Retailer;
  isSelected: false;
  onSelectRetailer: (retailer: Retailer) => void;
  onClear?: undefined;
  canChange?: undefined;
  dataAutoId?: string;
  roadLengthUnit: RoadLengthUnit;
  volvoCountryCode?: string;
};

export type RetailerCard = (
  props: SelectedRetailerCardProps | RetailerCardProps,
) => React.ReactNode;

const commonStyles = 'mt-16 flex-shrink flex-grow p-24 rounded';

const nonSelectedCardStyle = `${commonStyles} p-16 border-ring transition-colors hover:border-primary`;

const selectedCardStyle = `${commonStyles} border-ring-2 border-accent-blue`;

// A facility may have multiple addresses. If that's the case, we try to find the
// one that matches the market language. If none is found, get the first one.
export const pickAddressForMarket = (
  facility: RetailerDetails,
  languageCode: string,
) => {
  if (facility.addresses.length > 1) {
    const withMatchingLanguageCode = facility.addresses.find(
      (address) => address.languageCode?.toLowerCase() === languageCode,
    );
    if (withMatchingLanguageCode) {
      return withMatchingLanguageCode;
    }
  }

  return facility.addresses[0];
};

export const getName = (
  partnerOrganizationalUnitNames: {
    name: string;
    languageCode: string;
  }[],
  languageCode: string,
): string | undefined => {
  if (partnerOrganizationalUnitNames.length > 1) {
    const withMatchingLanguageCode = partnerOrganizationalUnitNames.find(
      (unit) => unit.languageCode.toLowerCase() === languageCode,
    )?.name;
    if (withMatchingLanguageCode) {
      return withMatchingLanguageCode;
    }
  }

  return partnerOrganizationalUnitNames[0].name;
};

export const DefaultRetailerCard: RetailerCard = ({
  retailer,
  onSelectRetailer,
  roadLengthUnit,
  isSelected,
  canChange,
  onClear,
  dataAutoId,
  volvoCountryCode,
}) => {
  const sharedContentTranslate = useSharedContentTranslate();
  const { languageCode } = getMarketSite(volvoCountryCode);

  if (!retailer) {
    console.log('retailer missing', retailer);
    return null;
  }
  const {
    partnerOrganizationalUnitNames,
    partnerOrganizationalUnitFacility,
    partnerOrganizationalUnitFacilities,
    distanceFromPointKm,
    distanceFromPointMiles,
  } = retailer;

  // The api data can return data in either of those two formats, so we need to support both
  const facility: RetailerDetails = partnerOrganizationalUnitFacilities
    ? partnerOrganizationalUnitFacilities[0]
    : partnerOrganizationalUnitFacility;

  const distance = {
    mile: {
      value: distanceFromPointMiles,
      unit: 'Miles',
    },
    kilometer: {
      value: distanceFromPointKm,
      unit: 'Kilometers',
    },
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSelectRetailer?.(retailer);
    }
  };

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onSelectRetailer?.(retailer);
  };

  const retailerName = getName(partnerOrganizationalUnitNames, languageCode);

  const address = pickAddressForMarket(facility, languageCode);
  if (!address) {
    console.log('retailer addresses missing', facility);
    return null;
  }

  return (
    <div
      role={!isSelected ? 'button' : undefined}
      tabIndex={!isSelected ? 0 : undefined}
      className={isSelected ? selectedCardStyle : nonSelectedCardStyle}
      onClick={onClick}
      onKeyDown={onKeyDown}
      data-testid={dataAutoId}
    >
      <h3 className="font-20 mb-8 font-medium">{retailerName}</h3>
      <div className="flex flex-row">
        <div className="flex-grow">
          <OptionalText>{address.addressLine1}</OptionalText>
          <OptionalText>{address.addressLine2}</OptionalText>
          <OptionalText>{address.addressLine3}</OptionalText>
          <OptionalText>{address.addressLine4}</OptionalText>
          <OptionalText>{address.city}</OptionalText>
        </div>
        {roadLengthUnit && distance[roadLengthUnit].value && (
          <p className="text-secondary">
            {formatDistance(distance[roadLengthUnit])}
          </p>
        )}
      </div>
      {isSelected && canChange && (
        <button type="button" className="link-inline" onClick={onClear}>
          {sharedContentTranslate('retailerPicker.clearSelection')}
        </button>
      )}
    </div>
  );
};

type RetailersProps = {
  visible: boolean;
  retailers: Retailer[];
  selectedRetailer: Retailer | null;
  nextRetailers: number | null;
  roadLengthUnit: RoadLengthUnit;
  onSelectRetailer: (retailer: Retailer) => void;
  loadingNext: boolean;
  canChange: boolean;
  onNext: () => void;
  onClear: () => void;
  customRetailerCard?: RetailerCard;
  volvoCountryCode: string;
};

export type RetailersHandle = {
  focus: () => void;
};

export const Retailers = forwardRef<RetailersHandle, RetailersProps>(
  (
    {
      visible,
      retailers,
      selectedRetailer,
      nextRetailers,
      roadLengthUnit,
      onSelectRetailer,
      loadingNext,
      canChange,
      onNext,
      onClear,
      customRetailerCard: CustomRetailerCard,
      volvoCountryCode,
    },
    ref,
  ) => {
    const sharedContentTranslate = useSharedContentTranslate();
    const focusRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => focusRef.current?.focus(),
    }));

    if (!visible) {
      return null;
    }

    const RetailerCard = CustomRetailerCard || DefaultRetailerCard;

    if (selectedRetailer) {
      return (
        <div className="flex">
          <RetailerCard
            isSelected
            retailer={selectedRetailer}
            canChange={canChange}
            onClear={onClear}
            dataAutoId="retailerPicker:selected"
            roadLengthUnit={roadLengthUnit}
            volvoCountryCode={volvoCountryCode}
          />
        </div>
      );
    }

    const hasRetailers = retailers && retailers.length > 0;

    const hasNext = Boolean(nextRetailers);

    return (
      <>
        <div tabIndex={-1} ref={focusRef} style={{ outline: 'none' }} />
        {hasRetailers && (
          <div aria-live="polite" id="retailers-aria">
            {/*
              to avoid race conditions, focus here so that there is something to focus on before
              retailers are generated. tabIndex=-1 ensures this does not affect user navigation,
              outline: none makes it invisible.
            */}
            {retailers.map((retailer, index) => (
              <RetailerCard
                key={index + '-' + retailer.partnerId}
                onSelectRetailer={onSelectRetailer}
                retailer={retailer}
                isSelected={false}
                dataAutoId="retailerPicker:option"
                roadLengthUnit={roadLengthUnit}
                volvoCountryCode={volvoCountryCode}
              />
            ))}
            {hasNext && (
              <div className="mt-16 text-center">
                <button
                  type="button"
                  className="button-text"
                  data-color="accent"
                  data-arrow="down"
                  disabled={loadingNext}
                  aria-controls="retailers-aria"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onNext();
                  }}
                >
                  {sharedContentTranslate('retailerPicker.nextRetailers')}
                </button>
              </div>
            )}
          </div>
        )}
      </>
    );
  },
);

Retailers.displayName = 'Retailers';
