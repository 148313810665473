import type CTAProps from '@contentstack/editorial-components/cta/cta.props';
import {
  errorCategories,
  reportDetailedErrorMessage,
} from '@utils/error-logging/error-logging-messages';
import { cssJoin } from '@volvo-cars/css/utils';
import { Track, type TrackProps } from '@volvo-cars/tracking';

export function CTA({
  buttonStyle,
  content,
  contentType,
  color = 'neutral',
  position,
  type,
  withChevron,
  trackingEventCategory = 'sfCta',
}: CTAProps & {
  trackingEventCategory?: string;
}) {
  if (!content) {
    reportDetailedErrorMessage({
      culprit: 'ContentStack > CTA UI Component',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['content'],
      },
    });

    return null;
  }

  const { accessibilityLabel, cta } = content;
  if (!accessibilityLabel || !cta) {
    reportDetailedErrorMessage({
      culprit: 'ContentStack > CTA UI Component',
      errorCategory: errorCategories.CONTENT_DELIVERY,
      details: {
        missingParts: ['accessibilityLabel', 'cta'],
      },
    });

    return null;
  }

  const isLink = type === 'link';
  return (
    <div
      className={`flex ${getButtonPosition(position)}`}
      data-testid={contentType?.id}
    >
      <Track
        {...getTrackingData(isLink, cta.href, trackingEventCategory, cta.text)}
      >
        <a
          data-arrow={!isLink && withChevron ? 'forward' : 'none'}
          aria-label={accessibilityLabel}
          className={getButtonStyles(type, buttonStyle)}
          data-color={color}
          href={cta.href}
          rel="noreferrer"
        >
          {cta.text}
        </a>
      </Track>
    </div>
  );
}

function getTrackingData(
  isLink: boolean,
  href: string,
  eventCategory: string,
  text = '',
): TrackProps {
  // Used to determine if the link is an anchor link
  // According to tracking specs, we should only append the |href if it's not an anchor link (starts with #)
  const isAnchorLink = href.startsWith('#');

  // Sometimes the ctaText has a trailing whitespce, remove that to avoid tracking issues
  const ctaText = text.trim();

  return {
    event: 'custom_event',
    eventAction: isLink ? 'link|click' : 'button|click',
    eventLabel: `${ctaText}${isAnchorLink ? '' : `|${href}`}`,
    customData: {
      eventCategory: eventCategory,
    },
  };
}

function getButtonPosition(position: CTAProps['position']) {
  switch (position) {
    case 'left':
      return 'justify-start';
    case 'right':
      return 'justify-end';
    default:
      return 'justify-center';
  }
}

function getButtonStyles(
  type: CTAProps['type'],
  buttonStyle: CTAProps['buttonStyle'],
) {
  switch (type) {
    case 'link':
      return 'link-inline';

    case 'text':
      return 'button-text';

    case 'button':
      return buttonStyle === 'outlined' ? 'button-outlined' : 'button-filled';

    default:
      break;
  }
  const buttonType =
    type === 'text'
      ? 'button-text'
      : buttonStyle === 'outlined'
        ? 'button-outlined'
        : 'button-filled';
  return cssJoin(buttonType);
}
