import image from '@contentstack/content-types/image/image.ct';
import type Image from '@contentstack/content-types/image/image.props';
import type ProductProps from '@contentstack/content-types/product/product.props';
import video from '@contentstack/content-types/video/video.ct';
import type Video from '@contentstack/content-types/video/video.props';
import type CampaignBannerProps from '@contentstack/editorial-components/campaign-banner/campaign-banner.props';
import type MediaProps from '@contentstack/editorial-components/media/media.props';

type CampaignBannerMedia = NonNullable<
  CampaignBannerProps['content']['mediaAsset']
>[number]['media'];
type ProductGridMedia = ProductProps['mediaList'][number]['media'];
type Media =
  | MediaProps['content']['media']
  | CampaignBannerMedia
  | ProductGridMedia;

function isImageAsset(media: Media): media is Image {
  return media?.contentType?.id === image.id;
}

function isVideoAsset(media: Media): media is Video {
  return media?.contentType?.id === video.id;
}

export function getMediaItem(media: MediaProps['content']['media']) {
  if (isImageAsset(media)) {
    const { alt, src } = media.imageAsset;
    return {
      imageSources: {
        sm: {
          src,
          alt,
        },
      },
    };
  }

  if (isVideoAsset(media)) {
    const { src, title } = media.videoAsset;
    return {
      imageSources: {
        sm: {
          src: '',
          alt: title || '',
        },
      },
      videoSourcesMp4: {
        default: src ?? '',
      },
    };
  }

  return null;
}
